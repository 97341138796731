import { FunctionComponent, memo } from 'react';
import './home/loaderNew/loaderNewStyles.scss';

interface Props {
  fon?: boolean;
}

export const Loader: FunctionComponent<Props> = memo(({ fon = true }) => {
  if (!fon) {
    return (
      <div className="loader-item">
        <div className="loader-item-bg"></div>
      </div>
    );
  }

  return (
    <div className="loader1_container">
      <div className="loader1"></div>
    </div>
    // <div className="loader">
    //   <div className="loader-item">
    //     <div className="loader-item-bg"></div>
    //   </div>
    // </div>
  );
});
